// Queries
import GET_AVAILABILITIES from "../../graphql/query/GetAvailabilities.gql";
// Mutations
import CREATE_AVAILABILITY from "../../graphql/mutation/CreateAvailabilityCalendar.gql";
import UPDATE_AVAILABILITY from "../../graphql/mutation/UpdateAvailabilityCalendar.gql";
import REMOVE_AVAILABILITY from "../../graphql/mutation/RemoveAvailabilityCalendar.gql";

export const namespaced = true;

const initState = () => ({});

export const state = initState();

export const actions = {
  async getAvailabilities({ commit }, { apolloClient }) {
    try {
      const {
        data: { Availabilities },
      } = await apolloClient.query({
        query: GET_AVAILABILITIES,
        fetchPolicy: "network-only",
      });

      return Availabilities.map((obj) => ({ ...obj, timed: true }));
    } catch (error) {
      throw error.graphQLErrors;
    }
  },
  async createAvailability(
    { commit },
    { data: { id, name, start, end }, apolloClient }
  ) {
    try {
      const {
        data: { CreateAvailabilityCalendar },
      } = await apolloClient.mutate({
        mutation: CREATE_AVAILABILITY,
        variables: {
          data: {
            id,
            name,
            type: name,
            start,
            end,
          },
        },
      });
      return CreateAvailabilityCalendar?.id;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async updateAvailability(
    { commit },
    { data: { id, name, start, end, recurrence }, apolloClient }
  ) {
    try {
      const {
        data: { UpdateAvailabilityCalendar },
      } = await apolloClient.mutate({
        mutation: UPDATE_AVAILABILITY,
        variables: {
          data: {
            id,
            name,
            type: name,
            start,
            end,
            recurrence,
          },
        },
      });
      return UpdateAvailabilityCalendar?.id;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async removeAvailability({ commit }, { data: { id }, apolloClient }) {
    try {
      const {
        data: { RemoveAvailabilityCalendar },
      } = await apolloClient.mutate({
        mutation: REMOVE_AVAILABILITY,
        variables: {
          data: {
            id,
          },
        },
      });
      return RemoveAvailabilityCalendar?.id;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
};

export default {
  namespaced,
  actions,
};
