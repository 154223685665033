<template>
  <v-btn :elevation="$attrs.elevation || 0" v-bind="$attrs">
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "BaseButton",
};
</script>

<style lang="scss">
.v-btn:not(.v-btn--round).v-size--default,
.v-btn:not(.v-btn--round).v-size--small {
  padding: 9px 12px 10px;
  height: auto;

  border: 1px solid $balloon-pink-500;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    $balloon-pink-500;
  box-shadow: 0px 1px 2px 0px rgba(18, 17, 30, 0.2);

  span {
    color: $resilient-white;
    font-size: 1rem;
    padding: 0 4px;
    letter-spacing: -0.16px;
  }

  .v-progress-circular .v-progress-circular__overlay {
    stroke: $resilient-white;
  }

  &:hover {
    border: 1px solid $balloon-pink-700;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      $balloon-pink-700;
    box-shadow: 0px 1px 2px 0px rgba(18, 17, 30, 0.2);

    &:before {
      opacity: 0;
    }
  }

  &:active {
    padding: 10px 12px 9px;
    border: 1px solid $balloon-pink-500;
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0.05) 100%
      ),
      linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      $balloon-pink-500;
    box-shadow: 0px 1px 2px 0px rgba(18, 17, 30, 0.09);
  }

  &.v-btn--disabled {
    opacity: 0.36;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      $balloon-pink-500 !important;

    .v-icon {
      color: inherit !important;
    }
  }

  /* BOTON BLANCO CON RELIEVE */
  &.alternative {
    border: none;
    background: linear-gradient(180deg, #fff 0%, $resilient-black-50 100%);
    box-shadow: 0px 0px 0px 1px rgba(37, 37, 37, 0.07),
      0px 1px 2px 0px rgba(18, 17, 30, 0.2);

    span {
      color: $cerulean-blue-800;
    }

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.01) 0%,
          rgba(0, 0, 0, 0.01) 100%
        ),
        linear-gradient(180deg, #fff -1.25%, $resilient-black-50 100%);
      box-shadow: 0px 0px 0px 1px rgba(37, 37, 37, 0.07),
        0px 1px 2px 0px rgba(18, 17, 30, 0.2);
    }

    &:active {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.02) 0%,
          rgba(0, 0, 0, 0.02) 100%
        ),
        linear-gradient(180deg, #fff 0%, $resilient-black-50 100%);
      box-shadow: 0px 0px 0px 1px rgba(37, 37, 37, 0.07),
        0px 1px 2px 0px rgba(18, 17, 30, 0.12),
        0px 1px 2px 0px rgba(18, 17, 30, 0.04) inset;
    }

    .v-progress-circular .v-progress-circular__overlay {
      stroke: $cerulean-blue-800;
    }

    &.v-btn--disabled {
      background: linear-gradient(
        180deg,
        #fff 0%,
        $resilient-black-50 100%
      ) !important;
    }
  }

  &.v-btn--text {
    background: none !important;
    border: none !important;
    box-shadow: none !important;

    span {
      color: $cerulean-blue-600;
    }

    &.risk {
      span {
        color: $amaranth-red-600;
      }
    }
  }
}

.v-btn:not(.v-btn--round).v-size--small,
.v-btn:not(.v-btn--round).v-size--small:active {
  padding: 4px;
}

.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0;
}
</style>
