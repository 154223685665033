import moment from "moment-timezone";
import Vue from "vue";

// Queries
import FETCH_ORGANIZATIONS from "../../graphql/query/FetchOrganizations.gql";
// Mutations
import CREATE_ORGANIZATION from "../../graphql/mutation/CreateOrganization.gql";
import REMOVE_ORGANIZATION from "../../graphql/mutation/RemoveOrganization.gql";
import UPDATE_ORGANIZATION from "../../graphql/mutation/UpdateOrganization.gql";
import UNLINK_PROGRAM from "../../graphql/mutation/UnlinkProgram.gql";

export const namespaced = true;

const initState = () => {
  return {
    organizations: [],
    isOrganizationsLoading: false,
  };
};

export const state = initState();

export const mutations = {
  SET_ORGANIZATIONS(state, organizations) {
    state.organizations = organizations;
  },
  SET_LOADING(state, status) {
    state.isOrganizationsLoading = status;
  },
  REMOVE_ORGANIZATION(state, removedOrganizationId) {
    state.organizations = state.organizations.filter(
      ({ _id }) => _id !== removedOrganizationId
    );
  },
  UPDATE_ORGANIZATION(state, updatedOrganization) {
    const organizationIndex = state.organizations.findIndex(
      (org) => org._id === updatedOrganization._id
    );
    if (organizationIndex === -1) return;
    Vue.set(state.organizations, organizationIndex, updatedOrganization);
  },
};

export const actions = {
  async createOrganization({ commit }, { apolloClient, ...data }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { CreateOrganization: organization },
      } = await apolloClient.mutate({
        mutation: CREATE_ORGANIZATION,
        fetchPolicy: "no-cache",
        variables: {
          data,
        },
      });

      if (!organization) throw new Error("No se pudo crear la organización");

      return organization;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async fetchOrganizations({ commit }, { filter, limit, skip, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { FetchOrganizations: organizations },
      } = await apolloClient.query({
        query: FETCH_ORGANIZATIONS,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            filter,
            limit,
            skip,
          },
        },
      });
      commit("SET_ORGANIZATIONS", organizations);
      return organizations;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async removeOrganization({ commit }, { _id, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { RemoveOrganization: removedOrganization },
      } = await apolloClient.mutate({
        mutation: REMOVE_ORGANIZATION,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            _id,
          },
        },
      });
      if (!removedOrganization._id)
        throw new Error("Hubo un error eliminando la organización");
      commit("REMOVE_ORGANIZATION", removedOrganization._id);
      return removedOrganization;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async updateOrganization({ commit }, { _id, data, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { UpdateOrganization: updatedOrganization },
      } = await apolloClient.mutate({
        mutation: UPDATE_ORGANIZATION,
        fetchPolicy: "no-cache",
        variables: {
          _id,
          data,
        },
      });
      if (!updatedOrganization._id)
        throw new Error("Hubo un error eliminando la organización");
      commit("UPDATE_ORGANIZATION", updatedOrganization);
      return updatedOrganization;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
  async unlinkProgram({ commit }, { organizationId, programId, apolloClient }) {
    try {
      commit("SET_LOADING", true);
      const {
        data: { UnlinkProgram: updatedOrganization },
      } = await apolloClient.mutate({
        mutation: UNLINK_PROGRAM,
        fetchPolicy: "no-cache",
        variables: { organizationId, programId },
      });
      return updatedOrganization;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export const getters = {
  organizations: (state, getters, rootState, rootGetters) => {
    const dateFormat = "DD-MM-YYYY";
    const timezone = rootGetters["users/getTimezone"];
    return state.organizations.map((organization) => {
      return {
        ...organization,
        createdAt: organization.createdAt
          ? moment.tz(+organization.createdAt, timezone).format(dateFormat)
          : null,
        updatedAt: organization.updatedAt
          ? moment.tz(+organization.updatedAt, timezone).format(dateFormat)
          : null,
        programs: organization.programs ?? [],
        totalPrograms: organization.programs?.length ?? 0,
      };
    });
  },
  isOrganizationsLoading: (state) => state.isLoading,
};
