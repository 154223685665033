import FETCH_RENDER_COACHEE_ACCELERATORS from "../../graphql/query/FetchRenderCoacheeAccelerators.gql";
import FETCH_RENDER_LEADER_DASHBOARD_ACCELERATORS from "../../graphql/query/FetchRenderLeaderDashboardAccelerators.gql";

export const namespaced = true;

const initState = () => {
  return {
    coachee: null,
    dashboard: null,
  };
};

export const state = initState();

export const mutations = {
  SET_COACHEE_ACCELERATORS(state, accelerators) {
    state.coachee = accelerators;
  },
  SET_DASHBOARD_ACCELERATORS(state, accelerators) {
    state.dashboard = accelerators;
  },
};

export const actions = {
  async fetchRenderCoacheeAccelerators(
    { commit },
    { apolloClient, userId, programId }
  ) {
    try {
      const {
        data: { RenderCoacheeAccelerators },
      } = await apolloClient.query({
        query: FETCH_RENDER_COACHEE_ACCELERATORS,
        fetchPolicy: "no-cache",
        variables: {
          programId,
          userId,
        },
      });

      commit("SET_COACHEE_ACCELERATORS", RenderCoacheeAccelerators);
      return RenderCoacheeAccelerators;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
  async fetchRenderLeaderDashboardAccelerators(
    { commit },
    { apolloClient, userId, programId }
  ) {
    try {
      const {
        data: { RenderLeaderDashboardAccelerators },
      } = await apolloClient.query({
        query: FETCH_RENDER_LEADER_DASHBOARD_ACCELERATORS,
        fetchPolicy: "no-cache",
        variables: {
          programId,
          userId,
        },
      });

      commit("SET_DASHBOARD_ACCELERATORS", RenderLeaderDashboardAccelerators);

      return RenderLeaderDashboardAccelerators;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};

export const getters = {
  coachee: (state) => state.coachee,
  dashboard: (state) => state.dashboard,
};
