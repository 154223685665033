const cache = {
  entry: {
    name: null,
  },
  flush() {
    localStorage.setItem("entry", JSON.stringify(cache.entry));
  },
  clear: () => {
    cache.entry = {
      name: null,
    };
    cache.flush();
  },
  get: () => {
    if (cache.entry.name) {
      const entry = { ...cache.entry };
      cache.clear();
      return { ...entry };
    }
    return null;
  },
  set: (to) => {
    cache.entry = { ...to };
    cache.flush();
  },
};

export default cache;
