import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {}
});

export const errorHandler = (content) => {
    let errors = [];
    if (content.errors && Array.isArray(content.errors)) {
        content.errors.forEach((err) => {
            errors.push(err.desc);
        });
    }
    return errors;
};

export default {
    custom: (path, content) => {
        let client = axios.create();
        client.defaults.headers.common = {};

        return new Promise((resolve, reject) => {
            if (content) {
                client.post(path, content).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            }
            else {
                client.get(path).then((response) => {
                    resolve(response);
                }).catch((err) => {
                    reject(err);
                });
            }
        });
    },
    request: (path, content) => {
        return new Promise((resolve, reject) => {
            if (content) {
                apiClient.post(path, content).then((response) => {
                    if (response.data.status != "success") {
                        reject(errorHandler(err.response.data.content));
                    }
                    resolve(response.data.content);
                }).catch((err) => {
                    console.error(err);
                    let errors = [ err.message ];
                    if (err.response && err.response.data && err.response.data.content) {
                        errors = errors.concat(errorHandler(err.response.data.content));
                    }
                    reject(errors);
                });
            }
            else {
                apiClient.get(path).then((response) => {
                    if (response.data.status != "success") {
                        reject(errorHandler(err.response.data.content));
                    }
                    resolve(response.data.content);
                }).catch((err) => {
                    console.error(err);
                    let errors = [ err.message ];
                    if (err.response && err.response.data && err.response.data.content) {
                        errors = errors.concat(errorHandler(err.response.data.content));
                    }
                    reject(errors);
                });
            }
        });
    },
    delete: (path, content) => {
        return new Promise((resolve, reject) => {
            apiClient.delete(path, content).then((response) => {
                if (response.data.status != "success") {
                    reject(errorHandler(err.response.data.content));
                }
                resolve(response.data.content);
            }).catch((err) => {
                console.error(err);
                let errors = [ err.message ];
                if (err.response && err.response.data && err.response.data.content) {
                    errors = errors.concat(errorHandler(err.response.data.content));
                }
                reject(errors);
            });
        });
    }
};
