(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define([], factory);
  } else if (typeof exports === "object") {
    module.exports = factory();
  } else {
    global.Index = factory();
  }
})(this, function () {
  var config = null;
  var gapiUrl = "https://apis.google.com/js/api:client.js";

  var gAuth = {
    install: function (Vue, options) {
      Vue.googleAuth = googleAuth;
      Vue.prototype.$googleAuth = googleAuth;

      if (typeof options === "object") {
        config = Object.assign(options, {
          scope: "profile email https://www.googleapis.com/auth/plus.login",
        });
      }
    },
  };

  let googleAuthProfileCallback = null;
  function googleAuth() {
    return {
      load: function (options) {
        return new Promise(function (resolve, reject) {
          if (window.gapi === undefined) {
            installClient()
              .then(function () {
                return initClient(options);
              })
              .then(function () {
                resolve();
              });
          } else if (window.gapi !== undefined && window.gapi.auth2 === undefined) {
            initClient(options).then(function () {
              resolve();
            });
          } else {
            resolve();
          }
        });
      },
      signIn: function (options) {
        return new Promise(function (resolve, reject) {
          window.gapi.auth2
            .getAuthInstance()
            .signIn(options)
            .then(
              function (googleUser) {
                resolve(googleUser);
              },
              function (error) {
                reject(error);
              }
            );
        });
      },

      getCalendarToken: function (options) {
        return new Promise(function (resolve, reject) {
          const authInstance = window.gapi.auth2.getAuthInstance();
          authInstance
            .grantOfflineAccess({
              scope: "https://www.googleapis.com/auth/calendar.events",
              redirect_uri: "postmessage",
            })
            .then(
              function (response) {
                const timeout = setTimeout(() => {
                  console.error("getting profile timeout");
                  googleAuthProfileCallback = null;
                  reject();
                }, 5000);
                googleAuthProfileCallback = () => {
                  clearTimeout(timeout);
                  resolve({
                    code: response.code,
                    name: authInstance.currentUser
                      .get()
                      .getBasicProfile()
                      .getEmail(),
                  });
                };
              },
              function (error) {
                reject(error);
              }
            );
        });
      },

      signOut: function () {
        return new Promise(function (resolve, reject) {
          window.gapi.auth2
            .getAuthInstance()
            .signOut()
            .then(
              function () {
                resolve();
              },
              function (error) {
                reject(error);
              }
            );
        });
      },
    };
  }

  function installClient() {
    return new Promise(function (resolve, reject) {
      var script = document.createElement("script");
      script.src = gapiUrl;
      script.onreadystatechange = script.onload = function () {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          setTimeout(function () {
            resolve();
          }, 500);
        }
      };
      document.getElementsByTagName("head")[0].appendChild(script);
    });
  }

  function initClient(options) {
    if (typeof options === "object") {
      config = Object.assign(options, {
        scope: "profile email https://www.googleapis.com/auth/plus.login",
      });
    }

    return new Promise(function (resolve, reject) {
      window.gapi.load("auth2", function () {
        const auth2 = window.gapi.auth2.init(config);
        auth2.currentUser.listen(() => {
          if (googleAuthProfileCallback) {
            googleAuthProfileCallback();
            googleAuthProfileCallback = null;
          }
        });
        resolve();
      });
    });
  }

  return gAuth;
});
