// Queries
import SEND_NOTIFICATION from "../../graphql/query/SendNotification.gql";
// Mutations

export const namespaced = true;

const initState = () => ({
  snackbar: {
    active: false,
    color: "success",
    content: "OK",
    contentClass: "text-h3 font-weight-medium",
    timeout: 4000,
    showClose: false,
  },
});

export const state = initState();

export const mutations = {
  SET_SNACKBAR(state, options) {
    state.snackbar = { ...state.snackbar, ...options };
  },
};

export const actions = {
  async sendNotification({ commit }, { apolloClient, ...data }) {
    try {
      const {
        data: { SendNotification },
      } = await apolloClient.query({
        query: SEND_NOTIFICATION,
        fetchPolicy: "no-cache",
        variables: {
          data,
        },
      });

      return SendNotification;
    } catch (error) {
      if (!error.graphQLErrors) console.error(error);
      console.error(error.graphQLErrors);
      throw error.graphQLErrors;
    }
  },
  callSnackbar({ commit }, options) {
    commit("SET_SNACKBAR", { ...options, active: true });
  },
};

export const getters = {
  getSnackbar(state) {
    return state.snackbar;
  },
};
