const sysadmin = [
  {
    path: "/administrador-usuarios",
    alias: "/admin-users",
    name: "AdminUsers",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        groups: ["sysadmin"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "sysadmin" */ "@/pages/Sysadmin/AdminUsers.vue"),
  },
  {
    path: "/info-sesiones",
    alias: "/info-sessions",
    name: "InfoSession",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        groups: ["sysadmin"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "sysadmin" */ "@/pages/Sysadmin/InfoSession.vue"),
  },
  {
    path: "/administrador-programas",
    alias: "/admin-programs",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        groups: ["sysadmin"],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "sysadmin" */ "@/pages/Sysadmin/AdminPrograms/AdminPrograms.vue"
      ),
    children: [
      {
        path: "",
        name: "AdminProgramsList",
        component: () =>
          import(
            /* webpackChunkName: "sysadmin-programs" */ "@/pages/Sysadmin/AdminPrograms/AdminProgramsList.vue"
          ),
      },
      {
        path: "crear",
        name: "AdminProgramsCreate",
        component: () =>
          import(
            /* webpackChunkName: "sysadmin-programs" */ "@/pages/Sysadmin/AdminPrograms/AdminProgramsCreate.vue"
          ),
      },
    ],
  },
  {
    path: "/programa/:programId",
    alias: "/program/:programId",
    name: "ProgramDetails",
    props: true,
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        groups: ["sysadmin"],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "sysadmin" */ "@/pages/Sysadmin/ProgramDetails.vue"
      ),
  },
  {
    path: "/administrador-organizaciones",
    alias: "/admin-organizations",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        groups: ["sysadmin"],
      },
    },
    component: () =>
      import(
        /* webpackChunkName: "sysadmin" */ "@/pages/Sysadmin/AdminOrganizations/AdminOrganizations.vue"
      ),
    children: [
      {
        path: "",
        name: "AdminOrganizationsList",
        component: () =>
          import(
            /* webpackChunkName: "sysadmin-organizations" */ "@/pages/Sysadmin/AdminOrganizations/AdminOrganizationsList.vue"
          ),
      },
      {
        path: "crear",
        name: "AdminOrganizationsCreate",
        component: () =>
          import(
            /* webpackChunkName: "sysadmin-organizations" */ "@/pages/Sysadmin/AdminOrganizations/AdminOrganizationsCreate.vue"
          ),
      },
    ],
  },
  {
    path: "/tablero",
    name: "Dashboard",
    meta: {
      layout: "Main",
      requires: {
        auth: true,
        verified: true,
        groups: ["sysadmin"],
      },
    },
    component: () =>
      import(/* webpackChunkName: "sysadmin" */ "@/pages/Sysadmin/Dashboard.vue"),
  },
];
export default sysadmin;
