import { createWidget } from "@typeform/embed";

export default {
  install(Vue, options) {
    Vue.prototype.$embedTypeform = function ({ formId, selector, options }) {
      createWidget(formId, {
        container: document.querySelector(selector),
        hideHeaders: true,
        hideFooter: true,
        opacity: 0,
        ...options,
      });
    };
  },
};
