import { uuid } from "vue-uuid";

// Queries
// Mutations
import LOG_ACTIVITY from "../../graphql/mutation/LogActivity.gql";
import { getBrowserInfo } from "../../lib/helpers";

export const namespaced = true;

const initState = () => ({
  sessionId: null,
  userId: null,
  update: false,
  activities: [],
});

export const state = initState();

export const mutations = {
  SET_COOKIES(state, content) {
    if (content.activities) state.activities = content.activities;

    if (content.session) state.session = content.session;
  },
};

export const actions = {
  async activity({ state, rootState, dispatch }, { activity, path, apolloClient }) {
    let session = rootState.users;
    let loggedOut = false;
    const isOverQueueLength =
      state.activities.length >= process.env.VUE_APP_ACTIVITY_QUEUE_LENGTH;

    // Logica de session
    if (state.userId && !session.user) loggedOut = true;
    if (!state.userId) {
      if (session?.user?._id && !state.userId) state.update = true;
      state.userId = session?.user?._id ?? undefined;
    }

    if (!state.sessionId) state.sessionId = uuid.v1();

    const activityData = {
      activity,
      path,
      ...getBrowserInfo(),
      environment: process.env.NODE_ENV,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      createdAt: new Date(),
    };

    state.activities.push(activityData);

    if (isOverQueueLength || loggedOut) {
      await dispatch("flush", { apolloClient });
    }

    if (loggedOut) {
      state.userId = null;
      state.sessionId = null;
      await dispatch("flush", { apolloClient });
    }
  },

  async flush({ state }, { apolloClient }) {
    try {
      // Store flush
      localStorage.setItem("cookies", JSON.stringify(state));
      const {
        data: { LogActivity },
      } = await apolloClient.mutate({
        mutation: LOG_ACTIVITY,
        variables: {
          data: state,
        },
      });

      // Clean state
      state.activities = [];
      state.update = false;
      return LogActivity;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
};

export const getters = {};
