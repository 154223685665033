// Queries
import GET_SCORES from "../../graphql/query/GetScores.gql";
// Mutations

export const namespaced = true;

const initState = () => ({
  scores: [],
  query: null,
});

export const state = initState();

export const mutations = {
  SET_SCORES(state, scores) {
    state.scores = scores;
  },
  /// Este seteo es ordinario, las acciones se le deben pasar el manejador de apolloCLient, cada llamada es una instancia distinta de apollo Client
  SET_HANDLER(state, query) {
    state.query = query;
  },
};

export const actions = {
  /// NO FUNCIONA PARA RECORDATORIO ///
  async startPolling({ commit, state }, { apolloClient }) {
    await apolloClient.addSmartQuery("getScores", {
      pollInterval: process.env.VUE_APP_SCORE_POLL_INTERVAL_TIME,
      query: GET_SCORES,
      fetchPolicy: "no-cache",
      manual: true,
      skip: false,
      result({ data }) {
        commit("SET_SCORES", data.GetScores);
      },
    });
    commit("SET_HANDLER", apolloClient.queries.getScores);
  },
  async refresh({ state }) {
    if (state.query) await state.query.refresh();
  },
};

export const getters = {
  scores: (state) => {
    return state.scores;
  },
};
