import GoogleIcon from "@/components/Icons/Google.vue";
import ArrowUpload from "@/components/Icons/ArrowUpload.vue";
import ArrowUploadImage from "@/components/Icons/ArrowUploadImage.vue";
import LeftExpandIcon from "@/components/Icons/LeftExpand.vue";
import RightExpandIcon from "@/components/Icons/RightExpand.vue";
import InfoDuotoneIcon from "@/components/Icons/InfoDuotone.vue";
import YesIcon from "@/components/Icons/Yes.vue";
import NotIcon from "@/components/Icons/Not.vue";
import CircleIcon from "@/components/Icons/Circle.vue";
import HandshakeIcon from "@/components/Icons/Handshake.vue";

export default {
  googleIcon: {
    component: GoogleIcon,
  },
  circleIcon: {
    component: CircleIcon,
  },
  arrowUpload: {
    component: ArrowUpload,
  },
  arrowUploadImage: {
    component: ArrowUploadImage,
  },
  leftExpandIcon: {
    component: LeftExpandIcon,
  },
  rightExpandIcon: {
    component: RightExpandIcon,
  },
  infoDuotoneIcon: {
    component: InfoDuotoneIcon,
  },
  yesIcon: {
    component: YesIcon,
  },
  notIcon: {
    component: NotIcon,
  },
  notIcon: {
    component: NotIcon,
  },
  handshakeIcon: {
    component: HandshakeIcon,
  },
};
