// Queries
import FETCH_FOCUS_FORM_RESPONSES from "../../graphql/query/FetchFocusFormResponses.gql";

export const namespaced = true;

const initState = () => {
  return {
    focusFormResponses: [],
  };
};

export const state = initState();

export const mutations = {
  SET_LOADING(state, status) {
    state.isProgramsLoading = status;
  },
  SET_FOCUS_FORM_RESPONSES(state, focusFormResponses) {
    state.focusFormResponses = focusFormResponses;
  },
};

export const actions = {
  async getFocusFormResponses({ commit }, { apolloClient, programId, userId }) {
    try {
      commit("SET_LOADING", true);
      const { data } = await apolloClient.query({
        query: FETCH_FOCUS_FORM_RESPONSES,
        variables: {
          programId,
          userId,
        },
        fetchPolicy: "no-cache",
      });
      commit("SET_FOCUS_FORM_RESPONSES", data.GetFocusFormResponses);
      return data.GetFocusFormResponses;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      commit("SET_LOADING", false);
    }
  },
};

export const getters = {
  focusFormResponses: (state) => state.focusFormResponses,
};
