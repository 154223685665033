import Vue from "vue";
import Vuex from "vuex";

import * as accelerators from "./module/accelerators";
import * as assets from "./module/assets";
import * as availabilities from "./module/availabilities";
import * as calendars from "./module/calendars";
import * as contents from "./module/contents";
import * as cookies from "./module/cookies";
import * as cronofy from "./module/cronofy";
import * as notifications from "./module/notifications";
import * as schedules from "./module/schedules";
import * as users from "./module/users";
import * as chat from "./module/chat";
import * as organizations from "./module/organizations";
import * as programs from "./module/programs";
import * as scores from "./module/scores";
import * as dashboard from "./module/dashboard";
import * as fees from "./module/fees";
import * as fixtures from "./module/fixtures";
import * as forms from "./module/forms";

Vue.use(Vuex, {
  strict: process.env.NODE_ENV === "development",
});

let store = new Vuex.Store({
  modules: {
    accelerators,
    assets,
    availabilities,
    calendars,
    contents,
    cookies,
    cronofy,
    notifications,
    schedules,
    users,
    chat,
    organizations,
    programs,
    scores,
    dashboard,
    fees,
    fixtures,
    forms,
  },
});

export default store;

// Usado por Jest
export const createStore = () => {
  return store;
};
