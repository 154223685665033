// Queries
import GET_COACH_SELECTED from "../../graphql/query/GetCoachSelected.gql";
import GET_USER from "../../graphql/query/GetUser.gql";
import GET_USERS from "../../graphql/query/GetUsers.gql";
import LOGIN from "../../graphql/query/Login.gql";
// Mutations
import ACTIVATE from "../../graphql/mutation/Activate.gql";
import CHANGE_PASSWORD from "../../graphql/mutation/ChangePassword.gql";
import CREATE_AVAILABILITY_LOCK from "../../graphql/mutation/CreateAvailabilityLock.gql";
import CREATE_CALENDAR from "../../graphql/mutation/CreateCalendar.gql";
import CREATE_USER from "../../graphql/mutation/CreateUser.gql";
import MODERATE_COACH from "../../graphql/mutation/ModerateCoach.gql";
import RECOVER_PASSWORD from "../../graphql/mutation/Recover.gql";
import REGISTER from "../../graphql/mutation/Register.gql";
import REMOVE_AVAILABILITY_LOCK from "../../graphql/mutation/RemoveAvailabilityLock.gql";
import REMOVE_CALENDAR from "../../graphql/mutation/RemoveCalendar.gql";
import REMOVE_USER from "../../graphql/mutation/RemoveUser.gql";
import SET_WIZARD_STEP from "../../graphql/mutation/SetWizardStep.gql";
import UPDATE_USER from "../../graphql/mutation/UpdateUser.gql";

import { formatUpdateUser } from "@/lib/formats";
import { getActiveProgram } from "@/lib/helpers";
import moment from "moment-timezone";

export const namespaced = true;

const initState = () => ({
  user: null,
  token: null,
  forms: {
    coachee: {
      about: {},
    },
  },
  updateAvatar: 100,
  coachSelected: null,
  users: [],
  verifiedStatus: ["verified", "inactive"],
});

export const state = initState();

export const mutations = {
  CLEAR_STATE(state) {
    Object.assign(state, initState());
  },
  SET_FORM_ABOUT_DATA(state, answers) {
    state.forms.coachee.about = answers;
  },
  SET_USER_DATA(state, { user }) {
    state.user = { ...user };
  },
  SET_TOKEN_DATA(state, { token }) {
    state.token = token;
    if (!token) {
      localStorage.clear();
    } else {
      localStorage.setItem("token", JSON.stringify(token));
    }
  },
  SET_WELCOME_STEPPER(state, step) {
    state.user.app.welcomeStepper = step;
  },
  SET_CALENDARS(state, calendars) {
    state.user.calendars = {
      ...calendars,
    };
  },
  SET_AVAILABILITY_LOCKS(state, availabilityLocks) {
    state.user = {
      ...state.user,
      availabilityLocks,
    };
  },
  SET_LOGBOOK(state, logbook) {
    state.logbook = logbook;
  },
  SET_COACH_SELECTED(state, { coach }) {
    state.coachSelected = coach;
  },
  SET_UPDATE_AVATAR(state, value) {
    state.updateAvatar += value;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
};

export const actions = {
  updateAvatar({ commit }, data = 1) {
    try {
      commit("SET_UPDATE_AVATAR", data);
      return;
    } catch (error) {
      throw error;
    }
  },
  async createUser({}, { apolloClient, data }) {
    try {
      const {
        data: { CreateUser },
      } = await apolloClient.mutate({
        mutation: CREATE_USER,
        variables: {
          data,
        },
      });
      return CreateUser;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async getUsers(
    { commit },
    { apolloClient, skip, limit, filter, commitResponse = true }
  ) {
    try {
      const {
        data: {
          Users: { users },
        },
      } = await apolloClient.query({
        query: GET_USERS,
        variables: {
          skip,
          limit,
          filter,
        },
        fetchPolicy: "no-cache",
      });
      if (commitResponse) {
        commit("SET_USERS", users);
      }

      return users;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async remove({}, { apolloClient, user }) {
    try {
      const {
        data: { Remove },
      } = await apolloClient.mutate({
        mutation: REMOVE_USER,
        variables: {
          data: { _id: user._id, email: user.email },
        },
      });
      return Remove;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async coachSelected({ commit }, { apolloClient }) {
    try {
      const {
        data: { CoachSelected },
      } = await apolloClient.query({
        query: GET_COACH_SELECTED,
        fetchPolicy: "no-cache",
      });
      commit("SET_COACH_SELECTED", { coach: CoachSelected });
      return CoachSelected;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async updateUser({ commit, getters }, { updateUserData, apolloClient }) {
    try {
      const {
        data: { UpdateUser },
      } = await apolloClient.mutate({
        mutation: UPDATE_USER,
        variables: {
          _id: state.user._id,
          data: formatUpdateUser(updateUserData),
        },
        fetchPolicy: "no-cache",
      });
      if (getters.userGroup !== "sysadmin") {
        commit("SET_USER_DATA", { user: UpdateUser });
      }
      return UpdateUser;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async expireUserConnection({ commit, state }, { apolloClient }) {
    try {
      if (!state.user?._id) return;
      await apolloClient.mutate({
        mutation: UPDATE_USER,
        variables: {
          _id: state.user._id,
          data: formatUpdateUser({ expireConnection: true }),
        },
        fetchPolicy: "no-cache",
      });
      return;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async moderate({ commit }, { email, moderate, apolloClient }) {
    try {
      const {
        data: { Moderate },
      } = await apolloClient.mutate({
        mutation: MODERATE_COACH,
        variables: {
          email,
          moderate,
        },
        fetchPolicy: "no-cache",
      });
      return Moderate;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async adminUpdateUser({ commit }, { _id, updateUserData, apolloClient }) {
    try {
      delete updateUserData.lastActivity;
      delete updateUserData._id;
      const updateUserResponse = await apolloClient.mutate({
        mutation: UPDATE_USER,
        variables: {
          _id,
          data: formatUpdateUser(updateUserData),
        },
        fetchPolicy: "no-cache",
      });
      return updateUserResponse;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async recover({}, { email, apolloClient }) {
    try {
      const response = await apolloClient.mutate({
        mutation: RECOVER_PASSWORD,
        variables: {
          email: email,
        },
      });
      return { response: "ok" };
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async changePassword({ commit }, { data, apolloClient }) {
    try {
      const response = await apolloClient.mutate({
        mutation: CHANGE_PASSWORD,
        variables: {
          token: data.token,
          password: data.password,
        },
      });
      const {
        data: { ChangePassword },
      } = response;
      return { message: "ok" };
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async activate({ commit }, { token, apolloClient }) {
    try {
      const {
        data: { Activate },
      } = await apolloClient.mutate({
        mutation: ACTIVATE,
        variables: {
          token,
        },
      });

      commit("SET_TOKEN_DATA", { token: Activate.token });
      commit("SET_USER_DATA", { user: Activate.user });
      return Activate.user;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async signin({ commit, state }, { email, accessToken, password, apolloClient }) {
    try {
      const response = await apolloClient.query({
        query: LOGIN,
        variables: {
          email,
          password,
        },
        fetchPolicy: "no-cache",
      });
      const {
        data: { Login },
      } = response;
      commit("SET_TOKEN_DATA", { token: Login.token });
      commit("SET_USER_DATA", { user: Login.user });
      return Login.user;
    } catch (error) {
      if (!state.user || !state.token) {
        throw error.graphQLErrors;
      }
      return state.user;
    }
  },
  async signout({ commit }) {
    if (localStorage.getItem("token")) {
      commit("SET_TOKEN_DATA", { token: null });
      commit("SET_USER_DATA", { user: null });
      localStorage.removeItem("token");
    }
    commit("CLEAR_STATE");
  },
  async getUser({ commit }, { apolloClient }) {
    try {
      const {
        data: { User },
      } = await apolloClient.query({
        query: GET_USER,
        fetchPolicy: "no-cache",
      });
      commit("SET_USER_DATA", { user: User });
      return User;
    } catch (error) {
      throw error.graphQLErrors;
    }
  },
  async signup(
    { commit },
    { email, name, accessToken, password, phone, apolloClient }
  ) {
    try {
      const {
        data: { Register },
      } = await apolloClient.mutate({
        mutation: REGISTER,
        variables: {
          email: email,
          first: name.first,
          last: name.last,
          password: password,
          phone: phone,
          type: "coachee",
        },
      });
      commit("SET_TOKEN_DATA", { token: Register.token });
      commit("SET_USER_DATA", { user: Register.user });
      return {
        token: Register.token,
      };
    } catch (error) {
      throw error.graphQLErrors;
    }
  },
  async createCalendar({ commit }, data) {
    try {
      const {
        data: { CreateCalendar },
      } = await data.apolloClient.mutate({
        mutation: CREATE_CALENDAR,
        variables: {
          data: data.args,
        },
      });
      commit("SET_CALENDARS", CreateCalendar);
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async removeCalendar({ commit, getters }, { index, apolloClient }) {
    try {
      const calendarToRemove = getters.getCalendars[index];
      const { name, type } = calendarToRemove;

      await apolloClient.mutate({
        mutation: REMOVE_CALENDAR,
        variables: {
          data: {
            name,
            type,
          },
        },
      });

      const newCalendars = {
        main: getters.getMainCalendar,
        others: getters.getOtherCalendars.filter(
          (calendar) => calendar.name !== name
        ),
      };
      commit("SET_CALENDARS", newCalendars);
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async createAvailabilityLock({ commit }, { data, apolloClient }) {
    // TODO: Api crea rango de bloqueo y devuelve rangos vigentes
    try {
      const {
        data: { CreateAvailabilityLock },
      } = await apolloClient.mutate({
        mutation: CREATE_AVAILABILITY_LOCK,
        variables: { data },
      });
      commit("SET_AVAILABILITY_LOCKS", CreateAvailabilityLock);
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async createAvailabilityLock({ commit }, { data, apolloClient }) {
    // TODO: Api crea rango de bloqueo y devuelve rangos vigentes
    try {
      const {
        data: { CreateAvailabilityLock },
      } = await apolloClient.mutate({
        mutation: CREATE_AVAILABILITY_LOCK,
        variables: { data },
      });
      commit("SET_AVAILABILITY_LOCKS", CreateAvailabilityLock);
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async removeAvailabilityLock({ commit }, { data, apolloClient }) {
    // Api elimina rango de bloqueo y devuelve rangos vigentes
    try {
      const {
        data: { RemoveAvailabilityLock },
      } = await apolloClient.mutate({
        mutation: REMOVE_AVAILABILITY_LOCK,
        variables: { data },
      });
      commit("SET_AVAILABILITY_LOCKS", RemoveAvailabilityLock);
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async setWizardStep({ commit }, { data, apolloClient }) {
    try {
      const {
        data: { SetWizardStep },
      } = await apolloClient.mutate({
        mutation: SET_WIZARD_STEP,
        variables: { data },
      });
      if (!SetWizardStep) return;

      commit("SET_WELCOME_STEPPER", SetWizardStep);
      return SetWizardStep;
    } catch (error) {
      throw error.graphQLErrors;
    }
  },
};

export const getters = {
  coaches: (state) =>
    state?.users
      .filter((user) => user._type.roles.includes("coach"))
      .map((coach) => ({
        ...coach,
        fullName: `${coach.name.first} ${coach.name.last}`,
      })) || [],
  cronofyAccountId: (state) => state.user?.auth?.cronofy?.accountId ?? undefined,
  enabledCoaches: (state, getters) =>
    getters.coaches.filter(
      ({ status, moderated }) => status === "verified" && moderated === true
    ),
  isAvailability: (state) => state?.user?.availability.length > 0,
  isCustomer: (state) => state?.user?._type.group === "customer",
  isCollaborator: (state) => state?.user?._type.group === "collaborator",
  isCoach: (state) => state?.user?._type.roles.includes("coach"),
  isSysadmin: (state, getters) =>
    state?.user?._type?.group === "sysadmin" &&
    state?.user?._type.roles.includes("admin") &&
    getters.activeRole === "admin",
  isAdminCoachee: (state) =>
    state?.user?._type.group === "customer" &&
    state?.user?._type.roles.includes("coachee") &&
    state?.user?._type.roles.includes("admin"),
  isLoggedIn: (state) => !!state?.user,
  isVerified: (state) => state?.verifiedStatus.includes(state?.user?.status),
  isInactive: (state) => state?.user?.status === "inactive",
  getUserType: (state) => state?.user?._type ?? undefined,
  getUserFullName: (state) => `${state.user?.name?.first} ${state.user?.name?.last}`,
  getUserFirstName: (state) => `${state.user?.name?.first}`,
  getWelcomeStep: (state, getters) => getters.user?.app?.welcomeStepper ?? 1,
  getLastWelcomeStep: (state, getters, rootState, rootGetters) => {
    const homeContent = rootGetters["contents/getViewByName"]("Home");
    const userTypeStepper = homeContent.body[getters.getUserType];
    if (!userTypeStepper) return null;
    return userTypeStepper.wizard.steps.length;
  },
  getCoachSelected: (state) => state.coachSelected || state.app?.coachSelected,
  getToken: (state) => state?.token ?? null,
  getCalendars: (state) => {
    const mainCalendar = state?.user?.calendars?.main
      ? { ...state.user.calendars.main, main: true }
      : null;
    const otherCalendars = state?.user?.calendars?.others
      ? state.user.calendars.others.map((cal) => ({ ...cal, main: false }))
      : [];
    const legacyCalendars = state?.user?.calendars?.legacy
      ? state.user.calendars.legacy.map((cal) => ({ ...cal, main: false }))
      : [];

    return mainCalendar
      ? [mainCalendar, ...otherCalendars, ...legacyCalendars]
      : [...otherCalendars, ...legacyCalendars];
  },
  getMainCalendar: (state) => state?.user?.calendars?.main ?? {},
  getOtherCalendars: (state) => state?.user?.calendars?.others ?? [],
  getTimezone: (state) =>
    state?.user?.location?.timezone?.description ||
    new Intl.DateTimeFormat().resolvedOptions().timeZone,
  getAvailabilityLocks: (state) => state?.user?.availabilityLocks ?? [],
  getAvailability: (state) => {
    // TODO: Evaluar intepretacion RAW de los datos desde el componente
    let availability = [
      { id: 0, menu: false, availability: { enabled: false, ranges: [] } },
      { id: 1, menu: false, availability: { enabled: false, ranges: [] } },
      { id: 2, menu: false, availability: { enabled: false, ranges: [] } },
      { id: 3, menu: false, availability: { enabled: false, ranges: [] } },
      { id: 4, menu: false, availability: { enabled: false, ranges: [] } },
      { id: 5, menu: false, availability: { enabled: false, ranges: [] } },
      { id: 6, menu: false, availability: { enabled: false, ranges: [] } },
    ];

    if (Array.isArray(state?.user?.availability)) {
      for (let availableDay of state.user.availability) {
        availability[availableDay.id].availability.enabled = true;
        for (let range of availableDay.ranges) {
          availability[availableDay.id].availability.ranges.push({
            start: range.start,
            end: range.end,
          });
        }
      }
    }

    return availability;
  },
  user: (state) => state.user,
  userGroup: (state) => state.user?._type?.group,
  userRoles: (state) => state.user?._type?.roles,
  conferenceUrl: (state) =>
    state.user?.typeDetails?.customer?.coachee?.conference?.conferenceUrl,
  activeProvider: (state) =>
    state.user?.typeDetails?.customer?.coachee?.conference?.activeProvider,
  wizardFlow: (state, getters) => {
    if (getters.user?.organization?.name === "CARDIF") return "cardif";
    if (["Walmart", "Organización 3.0"].includes(getters.user?.organization?.name))
      return "yourney";
    return getters?.activeProvider || "yourney";
  },
  activeRole: (state) => state.user?._type?.active,
  activeFeatures: (state, getters) =>
    state.user?.typeDetails?.[state.user._type.group]?.[getters.activeRole]
      ?.activeFeatures,
  getEmail: (state) => state.user?.email || "",
  getUserName: (state) => `${state.user?.name.first} ${state.user?.name.last}`,
  updateAvatar: (state) => state.updateAvatar,
  getAvatar: (state) => state.user?.picture?.medium || null,
  imagePosition: (state, getters) =>
    getters.getAvatar?.slice(-1) !== "1" ? "1" : "2",
  programExpiresAt: (state) =>
    getActiveProgram([...state.user?.programs]).expiresAt || null,
  userList: (state) => [...(state?.users || [])],
  getOrganizationName: (state) => state.user?.organization?.name || "",
  getPayment: (state) => state?.user?.payment || null,
  getCustomerCoacheePrograms: (state) => state?.user?.programs || null,
  getCustomerAdminPrograms: (state) =>
    state?.user?.typeDetails?.customer?.admin?.programs || null,
  getCollaboratorConsultantPrograms: (state) =>
    state?.user?.typeDetails?.collaborator?.consultant?.programs || null,
  getDob: (state) =>
    state.user && state.user.dob
      ? moment.unix(new Date(state.user?.dob / 1000)).format("DD/MM/YYYY") || ""
      : "",
  getTimezoneFull: (state, getters) =>
    `(GMT${moment.tz(getters.getTimezone).format("Z")}) ${getters.getTimezone}`,
  getProfile: (state, getters) => {
    const profile = {
      customer: {
        coachee: {
          personalInfo: [
            {
              label: "e-mail",
              data: getters.getEmail,
            },
            {
              label: "Nombre",
              data: getters.getUserFullName,
            },
            {
              label: "Teléfono",
              data: state.user?.phone,
            },
            {
              label: "Fecha de Nacimiento",
              data: getters.getDob,
            },
            {
              label: "Zona horaria",
              data: getters.getTimezoneFull,
            },
          ],
          organization: {
            name: state.user?.organization?.name || "",
            info: [
              {
                label: "Nombre Organización",
                data: state.user?.organization?.name || "",
              },
              {
                label: "Cargo",
                data: state.user?.typeDetails?.customer?.position || "",
              },
              {
                label: "Gerencia",
                data: state.user?.typeDetails?.customer?.management || "",
              },
              {
                label: "Nombres Programas",
                data: state.user.programs.map((p) => p.name).join(", "),
              },
            ],
          },
        },
        admin: {
          personalInfo: [
            {
              label: "e-mail",
              data: getters.getEmail,
            },
            {
              label: "Nombre",
              data: getters.getUserFullName,
            },
            {
              label: "Teléfono",
              data: state.user?.phone,
            },
            {
              label: "Fecha de Nacimiento",
              data: getters.getDob,
            },
            {
              label: "Zona horaria",
              data: getters.getTimezoneFull,
            },
          ],
          organization: {
            name: state.user?.organization?.name || "",
            info: [
              {
                label: "Nombre Organización",
                data: state.user?.organization?.name || "",
              },
              {
                label: "Cargo",
                data: state.user?.typeDetails?.customer?.position || "",
              },
              {
                label: "Gerencia",
                data: state.user?.typeDetails?.customer?.management || "",
              },
              {
                label: "Programas",
                data:
                  getters?.getCustomerAdminPrograms?.map((p) => p.name).join(", ") ||
                  "",
              },
            ],
          },
        },
      },
      collaborator: {
        coach: {
          personalInfo: [
            {
              label: "e-mail",
              data: getters.getEmail,
            },
            {
              label: "Nombre",
              data: getters.getUserFullName,
            },
            {
              label: "Teléfono",
              data: state.user?.phone,
            },
            {
              label: "Fecha de Nacimiento",
              data: getters.getDob,
            },
            {
              label: "Zona horaria",
              data: getters.getTimezoneFull,
            },
          ],
          paymentInfo: [
            {
              label: "DNI/RUT",
              data: state?.user?.payment?.dni || "",
            },
            {
              label: "Banco",
              data: state?.user?.payment?.bank || "",
            },
            {
              label: "Tipo Cuenta",
              data: state?.user?.payment?.account || "",
            },
            {
              label: "Número de cuenta",
              data: state?.user?.payment?.number || "",
            },
          ],
        },
        consultant: {
          personalInfo: [
            {
              label: "e-mail",
              data: getters.getEmail,
            },
            {
              label: "Nombre",
              data: getters.getUserFullName,
            },
            {
              label: "Teléfono",
              data: state.user?.phone,
            },
            {
              label: "Fecha de Nacimiento",
              data: getters.getDob,
            },
            {
              label: "Zona horaria",
              data: getters.getTimezoneFull,
            },
          ],
          paymentInfo: [
            {
              label: "DNI/RUT",
              data: state?.user?.payment?.dni || "",
            },
            {
              label: "Banco",
              data: state?.user?.payment?.bank || "",
            },
            {
              label: "Tipo Cuenta",
              data: state?.user?.payment?.account || "",
            },
            {
              label: "Número de cuenta",
              data: state?.user?.payment?.number || "",
            },
          ],
        },
      },
      sysadmin: {
        admin: {
          personalInfo: [
            {
              label: "e-mail",
              data: getters.getEmail,
            },
            {
              label: "Nombre",
              data: getters.getUserFullName,
            },
            {
              label: "Teléfono",
              data: state.user?.phone,
            },
            {
              label: "Fecha de Nacimiento",
              data: getters.getDob,
            },
            {
              label: "Zona horaria",
              data: getters.getTimezoneFull,
            },
          ],
        },
        consultant: {
          personalInfo: [
            {
              label: "e-mail",
              data: getters.getEmail,
            },
            {
              label: "Nombre",
              data: getters.getUserFullName,
            },
            {
              label: "Teléfono",
              data: state.user?.phone,
            },
            {
              label: "Fecha de Nacimiento",
              data: getters.getDob,
            },
            {
              label: "Zona horaria",
              data: getters.getTimezoneFull,
            },
          ],
        },
      },
    };
    const result = profile[state.user._type.group][state.user._type.active];
    return result;
  },
  getActiveProgram: (state) => getActiveProgram([...state.user?.programs]) || null,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
