// Queries
// Mutations
import CREATE_AVAILABILITY from "../../graphql/mutation/CreateAvailability.gql";

export const namespaced = true;

const initState = () => ({});

export const state = initState();

export const mutations = {
  SET_SOMETHING(state, content) {
    state.variable = content;
  },
};

export const actions = {
  async createAvailability({ state }, data) {
    await data.apolloClient.mutate({
      mutation: CREATE_AVAILABILITY,
      variables: {
        data: data.args,
      },
    });
  },
};

export const getters = {};
