<template>
  <component
    :is="cardComponent"
    v-bind="{ ...$attrs, ...$props, ...$emit }"
    @toggle="onToggle"
    @click="handlerAction"
    @close="$emit('close')"
  >
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
  </component>
</template>

<script>
import { defineAsyncComponent, defineComponent, ref } from "vue";

export default defineComponent({
  name: "BaseCard",
  props: {
    version: {
      type: String,
      default: "v1",
    },
  },
  model: {},
  setup(props, context) {
    const { emit } = context;
    const _emit = emit;
    const versionComponent = (version) =>
      defineAsyncComponent(() => {
        return import(`./Card-${version}.vue`);
      });
    function onToggle(value) {
      emit("toggle", value);
    }
    function handlerAction({ value, emit }) {
      _emit(emit, value);
    }

    return {
      cardComponent: ref(versionComponent(props.version)),
      onToggle,
      handlerAction,
    };
  },
});
</script>
