import pathsNames from "@/fixtures/pathsNames.json";
import conferenceProviders from "@/fixtures/conferenceProviders.json";

export default () => [
  {
    name: "pathsNames",
    status: "active",
    roles: ["sysadmin"],
    list: pathsNames,
  },
  {
    name: "conferenceProviders",
    status: "active",
    roles: ["sysadmin", "coachee"],
    list: conferenceProviders,
  },
];
