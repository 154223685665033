const DEFAULT_META = {
  title: "Yourney",
  description: "Tu viaje de crecimiento",
  image:
    "https://d9hhrg4mnvzow.cloudfront.net/web.yourney.io/d17f7a80-logo-azul_1031027000000000000028.png",
  imageAlt: "Logo de Yourney",
  locale: "es_419",
  twitter: {
    card: "summary",
    creator: "@trabajocsentido",
    content: "@trabajocsentido",
  },
  url: "https://app.yourney.io/",
};

const getMetaInfo = () => {
  const { title, description, image, imageAlt, locale, twitter, url } = DEFAULT_META;

  return {
    title: title,
    titleTemplate: "%s | Yourney",
    meta: [
      { name: "description", content: description },
      { name: "title", content: title },
      // Open Graph (Usado por Facebook)
      { property: "og:url", content: url },
      { property: "og:type", content: "website" },
      { property: "og:title", content: title },
      {
        property: "og:description",
        content: description,
      },
      { property: "og:image", content: image },
      { property: "og:image:alt", content: imageAlt },
      { property: "og:site_name", content: title },
      { property: "og:locale", content: locale },
      // Twitter Meta Tags (Preferencial para Twitter, pero usa Open Graph como fallback)
      { name: "twitter:card", content: twitter.card },
      { name: "twitter:creator", content: twitter.creator },
      { name: "twitter:site", content: twitter.content },
    ],
  };
};

export default getMetaInfo;
