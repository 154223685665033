import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/lib/locale/es";
import icons from "@/plugins/yourneyIcons.js";
// import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: {
          base: "#474AF0",
          lighten1: "#7990FF",
          lighten2: "#9FB7FF",
          lighten3: "#C4D4FF",
          lighten4: "#DEE8FF",
          lighten5: "#EDF2FF",
          darken1: "#2F2FD3",
          darken2: "#292BAA",
          darken3: "#292D86",
          darken4: "#18194E",
        },
        secondary: {
          base: "#E347A5",
          lighten1: "#EE78C3",
          lighten2: "#F5ACDC",
          lighten3: "#F9D1EC",
          lighten4: "#FBE8F5",
          lighten5: "#FCF3F9",
          darken1: "#D32F8A",
          darken2: "#B6206E",
          darken3: "#7E1D4F",
          darken4: "#4D0A2C",
        },
        accent: {
          base: "#004E98",
          darken1: "#524BFD",
        },
        disabled: "#565656",
        error: "#FD4B4B",
        info: "#2196F3",
        background: "#FFFFFF",
        success: "#4CAF50",
        warning: "#FFC400",
        white: "#FFFFFF",
      },
      dark: {
        primary: "#FFFFFF",
        secondary: "#E347A5",
        disabled: "#565656",
        accent: "#004E98",
        error: "#FD4B4B",
        info: "#2196F3",
        background: {
          base: "#524BFD",
          darken1: "#524BFD",
          darken2: "#242578",
        },
        success: "#4CAF50",
        warning: "#FFC400",
        black: "#252525",
      },
    },
  },
  icons: {
    values: icons,
  },
  lang: {
    locales: {
      es,
    },
    current: "es",
  },
});
