// Queries
import GET_CRONOFY_ELEMENT_TOKEN from "../../graphql/query/GetCronofyElementToken.gql";
import GET_CRONOFY_LINK_TOKEN from "../../graphql/query/GetCronofyLinkToken.gql";
import GET_CRONOFY_ACCESS_TOKEN from "../../graphql/query/GetCronofyAccessToken.gql";
// Mutations
import AUTHORIZE_CRONOFY_ACCOUNT from "../../graphql/mutation/AuthorizeCronofyAccount.gql";

export const namespaced = true;

const initState = () => ({
  element_token: null,
});

export const state = initState();

export const mutations = {
  SET_ELEMENT_TOKEN: (state, { elementToken }) => {
    state.element_token = elementToken;
  },
};

export const actions = {
  authorizeCronofyAccount: async (
    { commit },
    { code, userId, domain, apolloClient }
  ) => {
    try {
      const {
        data: { AuthorizeCronofyAccount },
      } = await apolloClient.mutate({
        mutation: AUTHORIZE_CRONOFY_ACCOUNT,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            code,
            redirectUrl: `${process.env.VUE_APP_ROOT_URL}/authorization`,
            userId,
            domain,
          },
        },
      });
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  getCronofyAccessToken: async (
    { commit },
    { code, redirect_uri, userId, apolloClient }
  ) => {
    try {
      const {
        data: { GetCronofyAccessToken },
      } = await apolloClient.query({
        query: GET_CRONOFY_ACCESS_TOKEN,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            code,
            redirect_uri,
            userId,
          },
        },
      });
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },

  getCronofyElementToken: async (
    { commit },
    { permissions, userId, apolloClient }
  ) => {
    try {
      const {
        data: { GetCronofyElementToken },
      } = await apolloClient.query({
        query: GET_CRONOFY_ELEMENT_TOKEN,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            permissions,
            userId,
          },
        },
      });
      commit({ type: "SET_ELEMENT_TOKEN", elementToken: GetCronofyElementToken });
      return true;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },

  getAuthorizeCronofyURL: async ({ dispatch }, { apolloClient }) => {
    try {
      const linkToken = await dispatch("getCronofyLinkToken", { apolloClient });
      let authorizeURL =
        `https://app.cronofy.com/oauth/authorize?response_type=code` +
        `&client_id=${process.env.VUE_APP_CRONOFY_CLIENT_ID}` +
        `&redirect_uri=${process.env.VUE_APP_ROOT_URL}/authorization` +
        `&scope=read_write&avoid_linking=true`;

      if (linkToken != "") {
        authorizeURL += `&link_token=${linkToken}`;
      }
      return authorizeURL;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },

  getCronofyLinkToken: async ({ commit }, { permissions, userId, apolloClient }) => {
    try {
      const {
        data: { GetCronofyLinkToken },
      } = await apolloClient.query({
        query: GET_CRONOFY_LINK_TOKEN,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            permissions,
          },
        },
      });

      return GetCronofyLinkToken.linkToken;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
};

export const getters = {
  getElementToken: (state) => state.element_token?.token ?? null,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
