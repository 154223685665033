<template>
  <div>
    <v-snackbar
      v-model="snackbar.active"
      app
      :color="snackbar.color || 'success'"
      :timeout="snackbar.timeout || 3000"
      :top="position.top"
      :right="position.right"
      :left="position.left"
      :bottom="position.bottom"
      :centered="position.centered"
      :content-class="snackbar.contentClass"
    >
      {{ snackbar.content || "OK" }}
      <template v-if="snackbar.showClose" v-slot:action="{ attrs }">
        <v-btn x-small icon text v-bind="attrs" @click="snackbar.active = false">
          <v-icon x-small> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BaseSnackbar",

  computed: {
    ...mapGetters("notifications", ["getSnackbar"]),

    position() {
      const isDesktopView = this.$vuetify.breakpoint.lgAndUp;
      return {
        top: true,
        right: isDesktopView,
        left: false,
        bottom: false,
        centered: false,
      };
    },
    snackbar: {
      get() {
        return this.getSnackbar;
      },
      set(value) {
        this.SET_SNACKBAR({ active: value });
      },
    },
  },
};
</script>
