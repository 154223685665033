import fixtures from "../../fixtures";

export const namespaced = true;

const initState = () => {
  return {
    fixtures: [],
  };
};

export const state = initState();

export const mutations = {
  SET_FIXTURES(state, fixtures) {
    state.fixtures = fixtures;
  },
};

export const actions = {
  async fetchFixtures({ commit }, { apolloClient, filter }) {
    try {
      const Fixtures = fixtures();
      commit("SET_FIXTURES", Fixtures);
      return Fixtures;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
};

export const getters = {
  pathsNames: (state) =>
    state?.fixtures?.find((fixture) => fixture.name === "pathsNames")?.list || [],
};
