import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import { createClient } from "../vue-apollo";
import IS_EXPIRED from "../graphql/query/isExpiredConnection.gql";
import cache from "./cache";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  try {
    const apolloClient = createClient();
    let isLoggedIn = store.getters["users/isLoggedIn"];
    let isVerified = store.getters["users/isVerified"];
    let userGroup = store.getters["users/userGroup"];
    let userRoles = store.getters["users/userRoles"];
    let activeRole = store.getters["users/activeRole"];

    if (isLoggedIn) {
      const {
        data: {
          User: { expireConnection },
        },
      } = await apolloClient.query({
        query: IS_EXPIRED,
        fetchPolicy: "no-cache",
      });

      if (expireConnection) {
        console.info(`Expire connections [${new Date().toLocaleDateString()}]`);
        store.commit("users/SET_USER_DATA", { user: null });
        store.commit("users/SET_TOKEN_DATA", { token: null });
        store.dispatch("users/signout");
      }
      isLoggedIn = store.getters["users/isLoggedIn"];
      isVerified = store.getters["users/isVerified"];
      userGroup = store.getters["users/userGroup"];
      userRoles = store.getters["users/userRoles"];
      activeRole = store.getters["users/activeRole"];
    }
    // Si existen usuario y token en Local Storage (y no loggeado), setear en Store
    let tokenString = to.name === "Signout" ? null : localStorage.getItem("token");

    if (tokenString && !isLoggedIn) {
      store.commit("users/SET_TOKEN_DATA", { token: JSON.parse(tokenString) });
      await store.dispatch("users/getUser", { apolloClient });
      isLoggedIn = store.getters["users/isLoggedIn"];
      isVerified = store.getters["users/isVerified"];
      userGroup = store.getters["users/userGroup"];
      userRoles = store.getters["users/userRoles"];
      activeRole = store.getters["users/activeRole"];
    }

    // Rutas protegidas
    if (
      isLoggedIn &&
      to.matched.some((record) => record.meta?.requires?.loggedOut)
    ) {
      console.warn("[Ruta protegida] Usuario no autorizado [Logged in]");
      next({ name: "Signout" });
      return;
    }
    // Para cambiar o preparar ambientes para roles especificos
    if (
      isLoggedIn &&
      to.matched.some((record) => record.meta?.requires?.checkRole)
    ) {
      console.warn("[Ruta protegida] Usuario no autorizado [checkRole]");
      console.warn("CHEQUEAR ROLE PARA META ASIGNAR LAYOUT");
      // Se asigna otro layout para un rol especifico
      if (
        (userGroup === "customer" &&
          activeRole === "admin" &&
          ["Wizard", "Quiz", "Sessions", "Chat", "Profile"].includes(to.name)) ||
        (userGroup === "customer" &&
          activeRole === "coachee" &&
          ["Wizard", "Quiz", "Sessions", "Chat", "Profile"].includes(to.name))
      ) {
        to.meta.layout = "v2/Main";
      }
    }
    if (!isLoggedIn && to.matched.some((record) => record.meta?.requires?.auth)) {
      console.warn("[Ruta protegida] Usuario no autorizado [Auth]");
      // Esta Ruta debe ir a Registro cuando se habilite el registro
      cache.set(to);
      next({ name: "Login" });
      return;
    }
    if (
      !isVerified &&
      isLoggedIn &&
      to.matched.some((record) => record.meta?.requires?.verified)
    ) {
      console.warn("[Ruta protegida] Usuario no autorizado [Verified]");
      next({ name: "Verify" });
      return;
    }
    if (
      isVerified &&
      to.matched.some((record) => record.meta?.requires?.unverified)
    ) {
      console.warn("[Ruta protegida] Usuario no autorizado [Unverified]");
      next({ name: "Home" });
      return;
    }
    if (
      to.matched.some((record) => {
        const requiresGroups = record.meta?.requires?.groups?.length > 0;
        const hasRequiredGroup = record.meta?.requires?.groups?.includes(userGroup);
        const groupRequirement = requiresGroups && !hasRequiredGroup;
        return groupRequirement;
      })
    ) {
      const youShould = to.meta.requires.groups.join(", ");
      console.warn(
        `[Ruta protegida] Usuario no autorizado - Groups [${youShould}] `
      );
      next({ name: "Home" });
      return;
    }
    if (
      to.matched.some((record) => {
        const requiresRoles = record.meta?.requires?.roles?.length > 0;
        if (record?.meta?.requires?.activeRole)
          return requiresRoles && !record.meta.requires.roles.includes(activeRole);
        const userHasSomeRequiredRole = userRoles?.some((role) =>
          record.meta?.requires?.roles?.includes(role)
        );
        const roleRequirement = requiresRoles && !userHasSomeRequiredRole;
        return roleRequirement;
      })
    ) {
      const youShould = to.meta.requires.roles.join(", ");
      console.warn(`[Ruta protegida] Usuario no autorizado - role [${youShould}] `);
      next({ name: "Home" });
      return;
    }
    if (
      to.matched.some((record) => {
        return (
          (activeRole === "coach" || activeRole === "coachee") &&
          record.meta?.requires?.wizardStep &&
          store.getters["users/getWelcomeStep"] < record.meta?.requires?.wizardStep
        );
      })
    ) {
      console.warn(
        `[Ruta protegida] Usuario no ha completado asistente. Path: ${to.fullPath}`
      );
      next({ name: "Wizard" });
      return;
    }

    next(); // Always call next()!
  } catch (error) {
    console.error(error);
  }
});

router.afterEach((to, from) => {
  store.dispatch("cookies/activity", {
    path: to.path,
    activity: "pageLoad",
    apolloClient: createClient(),
  });
});

export default router;

export const createRouter = () => {
  return new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });
};
