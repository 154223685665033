const cleanObj = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

const isEmpty = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
};

const flatten = (ob) => {
  let toReturn = {};

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == "object" && ob[i] !== null) {
      let flatObject = flatten(ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;
        toReturn[i + "." + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};

const unflatten = (ob) => {
  const result = {};
  for (const i in ob) {
    if (Object.prototype.hasOwnProperty.call(ob, i)) {
      const keys = i.match(/^\.+[^.]*|[^.]*\.+$|(?:\.{2,}|[^.])+(?:\.+$)?/g); // Just a complicated regex to only match a single dot in the middle of the string
      keys.reduce((r, e, j) => {
        return (
          r[e] ||
          (r[e] = isNaN(Number(keys[j + 1]))
            ? keys.length - 1 === j
              ? ob[i]
              : {}
            : [])
        );
      }, result);
    }
  }
  return isEmpty(result) ? {} : result;
};

export const formatUpdateUser = (user) => {
  const flattenUser = cleanObj(flatten(user));
  const { _id, auth, programs, organization, ...cleanUser } = unflatten(flattenUser);
  if (programs && programs.length > 0)
    cleanUser.programs = programs.map((p) => (p && p._id ? p._id : p));
  if (organization?._id) cleanUser.organization = organization._id;
  return cleanUser;
};
