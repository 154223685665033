import moment from "moment-timezone";
// Queries
import GET_COACHEE_SESSIONS from "../../graphql/query/GetCoacheeSessions.gql";
import GET_COACH_SCHEDULE from "../../graphql/query/GetCoachSchedule.gql";
import GET_MATCHING_COACHES from "../../graphql/query/GetMatchingCoaches.gql";
import GET_SAVED_MATCH from "../../graphql/query/GetSavedMatch.gql";
import GET_SESSION_LIST from "../../graphql/query/GetSessionList.gql";
import GET_SAT_TO_ANSWER from "../../graphql/query/GetSATToAnswer.gql";
import SUMMARY_SESSIONS from "../../graphql/query/SummarySessions.gql";
// Mutations
import CREATE_BLOCKED_PERIODS from "../../graphql/mutation/CreateBlockedPeriods.gql";
import CANCEL_SESSION from "../../graphql/mutation/CancelSession.gql";
import DELETE_AVAILABLE_PERIODS from "../../graphql/mutation/DeleteAvailablePeriods.gql";
import RESCHEDULE_SESSION from "../../graphql/mutation/RescheduleSession.gql";
import RATESESSION from "../../graphql/mutation/RateSession.gql";
import CREATE_SESSION from "../../graphql/mutation/CreateSession.gql";
import CLOSE_SESSION from "../../graphql/mutation/CloseSession.gql";
import SET_COACH_SESSION from "../../graphql/mutation/SetCoachSessions.gql";
import UPSERT_SESSION from "../../graphql/mutation/UpsertSession.gql";
import SESSION_LOGS from "../../graphql/mutation/SessionLogs.gql";
import UPSERT_AVAILABLE_PERIODS from "../../graphql/mutation/UpsertAvailablePeriods.gql";

export const namespaced = true;

const initState = () => ({
  loader: true,
  step: "load", // load -> select -> schedule -> done
  matchingCoaches: [],
  selectedCoach: undefined,
  coachSlotsAvailable: undefined,
  selectedSlot: undefined,
  selectedSession: undefined,
  sessionList: [],
  coacheeSessions: [],
  rescheduleData: {
    coach: undefined,
    slots: undefined,
  },
});

export const state = initState();

export const mutations = {
  SET_COACHEE_SESSIONS(state, sessions) {
    state.coacheeSessions = sessions;
  },
  SET_SCHEDULE_LOADER(state, loader) {
    state.loader = loader;
  },
  SET_SCHEDULE_STEP(state, step) {
    state.step = step;
  },
  SET_RESCHEDULE_DATA(state, rescheduleData) {
    state.rescheduleData = { ...rescheduleData };
  },
  SET_MATCHING_COACHES(state, matchingCoaches) {
    state.matchingCoaches = matchingCoaches;
  },
  SET_SAVED_MATCH(state, savedMatch) {
    state.savedMatch = savedMatch;
  },
  SET_SELECTED_COACH(state, { coach }) {
    state.selectedCoach = coach;
  },
  SET_COACH_SLOTS_AVAILABLE(state, { slots }) {
    state.coachSlotsAvailable = slots;
  },
  SET_SELECTED_SESSION(state, selectedSession) {
    state.selectedSession = selectedSession;
  },
  SET_SELECTED_SLOT(state, selectedSlot) {
    state.selectedSlot = selectedSlot;
  },
  SET_SESSION_LIST(state, { sessionList }) {
    state.sessionList = sessionList;
  },
  UPDATE_SESSION_LIST(state, sessionToUpdate) {
    state.sessionList = state.sessionList.map((s) =>
      s._id === sessionToUpdate._id ? sessionToUpdate : s
    );
  },
  CLEAN_STATE(state) {
    Object.assign(state, initState());
  },
};

export const actions = {
  cleanState({ commit }) {
    commit("CLEAN_STATE");
  },
  async fetchCoacheeSessions({ commit }, { data, apolloClient }) {
    try {
      let coacheeSessionsResponse = await apolloClient.query({
        query: GET_COACHEE_SESSIONS,
        fetchPolicy: "no-cache",
        variables: {
          data,
        },
      });
      commit("SET_COACHEE_SESSIONS", coacheeSessionsResponse.data.Sessions);
      return coacheeSessionsResponse.data.Sessions;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async fetchCoachSchedule({ commit }, { coachId, apolloClient }) {
    try {
      let coachScheduleResponse = await apolloClient.query({
        query: GET_COACH_SCHEDULE,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            coachId,
          },
        },
      });
      commit("SET_SELECTED_COACH", coachScheduleResponse.data.CoachSchedule);
      commit("SET_COACH_SLOTS_AVAILABLE", coachScheduleResponse.data.CoachSchedule);
      return coachScheduleResponse.data.CoachSchedule;
    } catch (error) {
      console.error(error.graphQLErrors);
      throw error.graphQLErrors;
    }
  },
  async fetchMatchingCoaches({ commit, rootState }, { apolloClient }) {
    try {
      let matchingCoachesResponse = await apolloClient.query({
        query: GET_MATCHING_COACHES,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            coacheeId: rootState.users.user._id,
          },
        },
      });
      commit("SET_MATCHING_COACHES", matchingCoachesResponse.data.MatchingCoaches);
      return matchingCoachesResponse;
    } catch (error) {
      console.error(error);
    }
  },
  async fetchSavedMatch({ commit, rootState }, { apolloClient }) {
    try {
      let savedMatchResponse = await apolloClient.query({
        query: GET_SAVED_MATCH,
        fetchPolicy: "no-cache",
        variables: {
          data: {
            coacheeId: rootState.users.user._id,
          },
        },
      });
      commit("SET_SAVED_MATCH", savedMatchResponse.data.SavedMatch);
      return savedMatchResponse.data.SavedMatch;
    } catch (error) {
      console.error(error);
    }
  },
  async fetchSessionList({ commit }, { data, apolloClient }) {
    try {
      const {
        data: { Sessions },
      } = await apolloClient.query({
        query: GET_SESSION_LIST,
        fetchPolicy: "no-cache",
        variables: {
          data,
        },
      });

      commit("SET_SESSION_LIST", { sessionList: Sessions });
      return Sessions;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },

  /**
   * Block a list of time periods (ranges) defined by their start and end
   * @param {Object[]} blockedPeriods
   * ```json
   * [
   *  {
   *    "start": "2022-06-05T14:45:00Z",
   *    "end": "2022-06-05T18:15:00Z"
   *  }
   * ]
   * ```
   * @returns
   */
  async createBlockedPeriods({ commit }, { blockedPeriods, apolloClient }) {
    try {
      const { data } = await apolloClient.mutation({
        mutation: CREATE_BLOCKED_PERIODS,
        fetchPolicy: "no-cache",
        variables: {
          blockedPeriods,
        },
      });
      return data;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async cancelSession({ commit }, { sessionId, apolloClient }) {
    try {
      const {
        data: { CancelSession },
      } = await apolloClient.mutate({
        mutation: CANCEL_SESSION,
        variables: {
          data: {
            sessionId,
          },
        },
      });
      commit("UPDATE_SESSION_LIST", CancelSession);
      return CancelSession;
    } catch (e) {
      console.error(e);
      throw e.graphQLErrors;
    }
  },
  async createSession({ commit }, { createdSession, apolloClient }) {
    try {
      const {
        data: { CreateSession },
      } = await apolloClient.mutate({
        mutation: CREATE_SESSION,
        variables: {
          data: createdSession,
        },
      });
      return CreateSession;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async closeSession({ commit }, { sessionId, apolloClient }) {
    try {
      const {
        data: { CloseSession },
      } = await apolloClient.mutate({
        mutation: CLOSE_SESSION,
        variables: {
          data: {
            sessionId,
          },
        },
      });
      return CloseSession;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async setCoachSessions({}, { coachId, apolloClient }) {
    try {
      const {
        data: { SetCoachSessions },
      } = await apolloClient.mutate({
        mutation: SET_COACH_SESSION,
        variables: {
          data: {
            coachId,
          },
        },
      });

      return true;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async sessionLogs({ commit }, { sessionId, logs, apolloClient }) {
    try {
      const data = await apolloClient.mutate({
        mutation: SESSION_LOGS,
        variables: {
          data: {
            sessionId,
            logs,
          },
        },
      });
      return true;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async upsertSession(
    { commit },
    { sessionId, coachId, coacheeId, rating, scheduledSlot, state, apolloClient }
  ) {
    try {
      const {
        data: { UpsertSession },
      } = await apolloClient.mutate({
        mutation: UPSERT_SESSION,
        variables: {
          data: {
            sessionId,
            coachId,
            coacheeId,
            rating,
            scheduledSlot,
            state,
          },
        },
      });
      commit("UPDATE_SESSION_LIST", UpsertSession);
      return UpsertSession;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async deleteAvailablePeriods(context, { availabilityPeriods, apolloClient }) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_AVAILABLE_PERIODS,
        variables: {
          data: { availabilityPeriods },
        },
      });
      return { ok: true };
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async insertAvailablePeriods(
    { commit },
    { availabilityPeriods, timezone, apolloClient }
  ) {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPSERT_AVAILABLE_PERIODS,
        variables: {
          data: { availabilityPeriods, timezone },
        },
      });
      return { ok: true };
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async rescheduleSession(
    { commit },
    { rescheduledSession: { sessionId, scheduledSlot }, apolloClient }
  ) {
    try {
      const {
        data: { UpdateSession },
      } = await apolloClient.mutate({
        mutation: RESCHEDULE_SESSION,
        variables: {
          data: {
            sessionId,
            scheduledSlot,
          },
        },
      });

      commit("UPDATE_SESSION_LIST", UpdateSession);
      return UpdateSession;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async updateSession({ commit }, { data, apolloClient }) {
    try {
      const {
        data: { UpdateSession },
      } = await apolloClient.mutate({
        mutation: RESCHEDULE_SESSION,
        variables: {
          data,
        },
      });

      commit("UPDATE_SESSION_LIST", UpdateSession);
      return UpdateSession;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async rateSession(
    { commit },
    { ratingSession: { sessionId, rating }, apolloClient }
  ) {
    try {
      const {
        data: { RateSession },
      } = await apolloClient.mutate({
        mutation: RATESESSION,
        variables: {
          data: {
            sessionId,
            rating,
          },
        },
      });

      commit("UPDATE_SESSION_LIST", RateSession);
      return RateSession;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async getSATToAnswer({ commit }, { apolloClient }) {
    try {
      const {
        data: { SATToAnswer },
      } = await apolloClient.query({
        query: GET_SAT_TO_ANSWER,
        fetchPolicy: "no-cache",
      });
      return SATToAnswer;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async summarySessions({ commit }, { data, apolloClient }) {
    try {
      const {
        data: { SessionsSummary },
      } = await apolloClient.query({
        query: SUMMARY_SESSIONS,
        fetchPolicy: "no-cache",
        variables: {
          data,
        },
      });
      return SessionsSummary;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
};

export const getters = {
  getAppointmentDetails: (state) => ({
    coach: `${state?.selectedCoach?.name?.first ?? ""}${
      state?.selectedCoach ? " " : ""
    }${state?.selectedCoach?.name?.last ?? ""}`,
    date:
      state?.selectedSlot?.dateTime.slice(0, 10).split("-").reverse().join("-") ??
      "",
    time: state?.selectedSlot?.dateTime.slice(11, 16) ?? "",
  }),
  getMatchingCoaches: (state) =>
    state.matchingCoaches.map((matchingCoach) => ({
      ...matchingCoach,
      picture: {
        large: matchingCoach.picture?.large ?? null,
        medium: matchingCoach.picture?.medium ?? null,
        thumbnail: matchingCoach.picture?.thumbnail ?? null,
      },
    })),
  getSelectedCoach: (state) =>
    state.selectedCoach
      ? {
          ...state.selectedCoach,
          coach: {
            ...state.selectedCoach.coach,
            picture: {
              large: state.selectedCoach?.coach?.picture?.large ?? null,
              medium: state.selectedCoach?.coach?.picture?.medium ?? null,
              thumbnail: state.selectedCoach?.coach?.picture?.thumbnail ?? null,
            },
          },
        }
      : null,
  getSelectedSession: (state) => state.selectedSession,
  getSelectedSlot: (state) => state.selectedSlot,
  getAvailableSlot: (state) => state.coachSlotsAvailable,
  getScheduleStep: (state) => state.step,
  getSessionList: (state) => state.sessionList,
  getNextSessionList: (state, getters) =>
    getters.getSessionList.filter(({ state, slot }) => {
      const nowMoment = moment(new Date());
      const enableConferenceMoment = moment(slot).subtract(10, "minutes");
      return state === "active" && nowMoment.isBefore(enableConferenceMoment);
    }),
  getActiveSessionList: (state, getters) =>
    getters.getSessionList
      .filter(({ state }) => ["active", "inprogress"].includes(state))
      .sort((a, b) => new Date(a.slot).getTime() - new Date(b.slot).getTime()),
  getFinishedSessionList: (state, getters) =>
    getters.getSessionList
      .filter(({ state }) =>
        [
          "pending",
          "cancelled",
          "expired",
          "finished",
          "finished_without_coach",
          "finished_without_coachee",
          "unfinished_without_coachee",
          "unfinished_with_parties",
          "cancelled_on_time",
          "cancelled_off_time",
          "finished_outside",
          "cancelled_with_reschedulling",
          "unfinished_without_parties",
        ].includes(state)
      )
      .sort((a, b) => new Date(b.slot).getTime() - new Date(a.slot).getTime()),
  getInProgressSession: (state, getters) =>
    getters.getSessionList.filter((session) => {
      const nowMoment = moment();
      const conferenceMoment = moment(session.slot);
      const sessionsQuantityDurations =
        process.env.VUE_APP_SESSION_DURATION_QUANTITY;
      const sessionUnitDuration = process.env.VUE_APP_SESSION_DURATION_UNIT;
      const isBetween = nowMoment.isBetween(
        conferenceMoment.clone().subtract(10, "minutes"),
        conferenceMoment.clone().add(sessionsQuantityDurations, sessionUnitDuration)
      );
      return ["inprogress"].includes(session.state) || isBetween;
    }),
  getRescheduleData: (state) => ({
    coach: {
      ...state.rescheduleData.coach,
      picture: {
        large: state.rescheduleData?.coach?.picture?.large ?? null,
        medium: state.rescheduleData?.coach?.picture?.medium ?? null,
        thumbnail: state.rescheduleData?.coach?.picture?.thumbnail ?? null,
      },
    },
    slots: state?.rescheduleData?.slots ?? [],
  }),
  isFirstSession: (state, getters, rootState, rootGetters) => {
    if (rootGetters["users/getUserType"] !== "coachee") return null;
    return state.sessionList.length < 1;
  },
  isScheduleLoading: (state) => state.loader,
};
