<template>
  <div>
    <h1 class="text-h1 font-weight-bold primary--text">
      <slot />
    </h1>
    <v-divider v-if="divider" class="d-none d-lg-block" />
  </div>
</template>

<script>
export default {
  name: "BaseTitle",
  props: {
    divider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
