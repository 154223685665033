import { pages } from "@/fixtures/content";

// Queries
import GET_FORMS from "../../graphql/query/GetForms.gql";
import GET_USER_STAGES from "../../graphql/query/GetUserStages.gql";
// Mutations

export const namespaced = true;

const initState = () => ({
  forms: [],
  pages: { ...pages },
  userStages: [],
});

export const state = initState();

export const mutations = {
  SET_FORMS_DATA(state, data) {
    state.forms = data;
  },
  SET_JSON_CONTENT(state, { type, content }) {
    state[type] = JSON.parse(content);
  },
};

export const actions = {
  async getForms({ commit }, { apolloClient }) {
    try {
      const response = await apolloClient.query({
        query: GET_FORMS,
        fetchPolicy: "no-cache",
      });
      const {
        data: { FormsContent },
      } = response;
      commit("SET_FORMS_DATA", FormsContent);
      return FormsContent;
    } catch (error) {
      throw error.graphQLErrors;
    }
  },
  async getUserStages({ commit }, { apolloClient }) {
    try {
      const response = await apolloClient.query({
        query: GET_USER_STAGES,
        fetchPolicy: "no-cache",
      });

      const {
        data: { GetUserStages },
      } = response;

      commit("SET_JSON_CONTENT", {
        type: "userStages",
        content: GetUserStages.content,
      });

      return;
    } catch (error) {
      throw error.graphQLErrors;
    }
  },
};

export const getters = {
  pages: (state) => state.pages,
  getCoacheeForms: (state) => state.forms.filter((f) => f._type === "coachee"),
  getCoachForms: (state) => state.forms.filter((f) => f._type === "coach"),
  getViewsContent: (state) => Object.values(state.pages),
  getViewByName: (_, getters) => (name) =>
    getters.getViewsContent.find((page) => page.name === name),
  userStages: (state) => state.userStages,
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
