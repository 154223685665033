import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixins from "./mixins";
import { createProvider } from "./vue-apollo";
import vuetify from "./plugins/vuetify";
import AppLayout from "@/layouts/AppLayout";
import BaseAvatar from "@/components/Base/Avatar";
import BaseButton from "@/components/Base/Button";
import BaseCard from "@/components/Base/Card";
import BaseTitle from "@/components/Base/Title";
import * as Sentry from "@sentry/vue";
import "regenerator-runtime/runtime";
import GoogleAuth from "@/lib/gapi";
import Typeform from "@/plugins/typeform";
import "@typeform/embed/build/css/widget.css";
import "vue-filter-table-component";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";

// Componentes globales
Vue.component("AppLayout", AppLayout);
Vue.component("BaseAvatar", BaseAvatar);
Vue.component("BaseButton", BaseButton);
Vue.component("BaseCard", BaseCard);
Vue.component("BaseTitle", BaseTitle);

Vue.use(VueTelInputVuetify, {
  vuetify,
});

// Plugins y librerías
Vue.use(VueMeta);
Vue.use(GoogleAuth, { client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID });
Vue.use(Typeform);

Vue.mixin(mixins);

Vue.googleAuth().load();

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://919844b36afc30a6493f3e9537ddecaf@o4506348802080768.ingest.sentry.io/4506418649825280",
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/testing\.yourney\.io/,
    /^https:\/\/app\.yourney\.io/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
