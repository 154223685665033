<template>
  <div></div>
</template>

<script>
import { createSlider } from "@typeform/embed";
import { mapActions, mapGetters } from "vuex";
import "@typeform/embed/build/css/slider.css";

export default {
  name: "BaseScoreDialog",
  data() {
    return {
      slider: undefined,
      currentScore: 0,
      scoring: false,
    };
  },

  computed: {
    ...mapGetters("scores", ["scores"]),
    ...mapGetters("users", ["getEmail"]),
  },

  watch: {
    scores(newVal) {
      if (!this.scoring) this.create();
    },
  },
  async mounted() {
    await this.startPolling({ apolloClient: this.$apollo });
  },

  methods: {
    ...mapActions("scores", ["startPolling"]),

    open(score) {
      if (score._id && score.path) {
        const slider = createSlider(score.path, {
          autoresize: true,
          autoClose: 1000,
          onSubmit: this.close,
          onClose: this.close,
          hidden: {
            score_id: score._id,
            environment: process.env.NODE_ENV,
            email: this.getEmail,
          },
          position: "left",
        });
        this.slider = slider;
        slider.open();
      }
    },

    create() {
      if (!this.scores.length) return;
      this.currentScore = 0;
      this.scoring = true;
      this.open(this.scores[this.currentScore]);
    },

    async close() {
      this.currentScore++;
      if (this.scores[this.currentScore]) this.open(this.scores[this.currentScore]);
      else this.scoring = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
