import Talk from "talkjs";
const { VUE_APP_ASSETS_AVATAR, VUE_APP_ASSETS_BASE_PATH } = process.env;
const avatarDefault = `${VUE_APP_ASSETS_BASE_PATH}${VUE_APP_ASSETS_AVATAR}/default`;

export const namespaced = true;

const initState = () => ({
  unread: [],
});

export const state = initState();

export const mutations = {
  SET_UNREAD_CONVERSATION(state, payload) {
    state.unread = [...payload];
  },
};

export const actions = {
  async createUser({}, user) {
    const { name, _id, email, picture, _type } = user;
    try {
      if (!name && !_id && !email && !_type) throw TypeError("MISSING PARAM(S)");
      const userChat = new Talk.User({
        id: _id,
        name: `${name.first} ${name.last}`,
        email,
        photoUrl: picture?.medium || avatarDefault,
        welcomeMessage: null,
        role: _type.roles.includes("coachee") ? "coachee" : "coach",
      });
      return userChat;
    } catch (error) {
      throw error;
    }
  },
  async createConversation({}, { me, other, chatSession }) {
    try {
      const conversation = await chatSession.getOrCreateConversation(
        Talk.oneOnOneId(me, other)
      );
      return conversation;
    } catch (error) {
      throw error;
    }
  },
  async createSession({}, { me }) {
    try {
      const session = await new Talk.Session({
        appId: process.env.VUE_APP_TALKJS_APPID,
        me,
      });
      return session;
    } catch (error) {
      throw error;
    }
  },
  async readyToTalk() {
    try {
      return await Talk.ready;
    } catch (error) {
      throw error;
    }
  },
  setUnread({ commit }, conversations) {
    commit("SET_UNREAD_CONVERSATION", [...conversations]);
    return true;
  },
};

export const getters = {
  me: (state) => state?.me,
  getUnreadConversations: ({ unread }) => [...unread],
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
