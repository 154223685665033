// Queries
import GET_FEES from "../../graphql/query/GetFees.gql";
import UPSERT_FEE from "../../graphql/mutation/UpsertFee.gql";

// Mutations

export const namespaced = true;

const initState = () => {
  return {
    fees: [],
  };
};

export const state = initState();

export const mutations = {
  SET_FEES(state, fees) {
    state.fees = fees;
  },
  UPSERT_FEE(state, upsertedFee) {
    const existsAtIndex = state.fees.findIndex((fee) => fee._id === upsertedFee._id);
    if (existsAtIndex !== -1) {
      state.fees[existsAtIndex] = upsertedFee;
    } else {
      state.fees.push(upsertedFee);
    }
    state.fees = [...state.fees];
  },
};

export const actions = {
  async getFees({ commit }, { apolloClient, skip, limit, filter, sort }) {
    try {
      const {
        data: { Fees },
      } = await apolloClient.query({
        query: GET_FEES,
        variables: {
          skip,
          limit,
          filter,
          sort,
        },
        fetchPolicy: "no-cache",
      });
      commit("SET_FEES", Fees);
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
  async upsertFee({ commit }, { data, apolloClient }) {
    try {
      const {
        data: { UpsertFee },
      } = await apolloClient.mutate({
        mutation: UPSERT_FEE,
        variables: {
          data,
        },
        fetchPolicy: "no-cache",
      });
      commit("UPSERT_FEE", UpsertFee);
      return UpsertFee;
    } catch (error) {
      console.error(error);
      throw error.graphQLErrors;
    }
  },
};

export const getters = {
  fees: (state) => state.fees,
};
