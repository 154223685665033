<template>
  <component
    :is="avatarComponent"
    v-bind="{ ...$attrs, ...$props }"
    @click="$emit('click')"
  >
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
  </component>
</template>

<script>
import { defineAsyncComponent, defineComponent, ref } from "vue";

export default defineComponent({
  name: "BaseAvatar",
  props: {
    version: {
      type: String,
      default: "v1",
    },
  },
  setup(props) {
    const versionComponent = (version) =>
      defineAsyncComponent(() => {
        return import(`./Avatar-${version}.vue`);
      });
    return {
      avatarComponent: ref(versionComponent(props.version)),
    };
  },
});
</script>
